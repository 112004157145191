<template>
  <v-card
    class="panel-card"
    outlined
    style="height: 100%;"
  >
    <v-skeleton-loader
      :loading="isLoading"
      type="image"
      style="height: 100%;"
    >
      <!-- <img :src="`https://fakeimg.pl/420x225/ffffff/?text=${panel.title}`" /> -->

      <iframe
        v-if="panelSrc"
        class="panel-iframe"
        async
        :src="panelSrc"
        width="100%"
        height="100%"
        frameborder="0"
      />
      <v-row
        v-else-if="error"
        class="error--text px-2 py-1"
        no-gutters
      >
        {{ error }}
      </v-row>
      <v-row
        v-else
        class="grey--text"
        no-gutters
        justify="center"
        align="center"
        style="height: 100%;"
      >
        {{ $t('page.panels.no_panel_src') }}
      </v-row>
    </v-skeleton-loader>
    <v-menu
      v-if="showMenu && !readonly"
      class="panel-menu"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="panel-menu-activator"
          v-bind="attrs"
          icon
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="isProjectOwner || roleDashboard.update"
          @click="editPanel"
        >
          <v-list-item-icon class="panel-menu-list-item-icon">
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('page.panels.edit_panel_title') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="isProjectOwner || roleDashboard.delete"
          @click="deletePanel"
        >
          <v-list-item-icon class="panel-menu-list-item-icon">
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('page.panels.delete_panel') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card>
</template>

<script>
import { getTimeRangeByRoute } from '@/components/TimeRangeSelect/TimeRangeSelect'
import { mapGetters } from 'vuex'

const qkFrom = process.env.VUE_APP_QUERY_KEY_TIME_FROM
const qkTo = process.env.VUE_APP_QUERY_KEY_TIME_TO

export default {
  name: 'PanelCard',

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    dashboard: {
      type: Object,
      default: null
    },
    panel: {
      type: Object,
      default: () => ({})
    },
    useFeatureProperties: {
      type: [Boolean, Object],
      default: false
    }
  },

  data: () => ({
    isLoading: false,
    snapshotUrl: '',
    error: null
  }),

  computed: {
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute',
      getDashboardByRoute: 'dashboards/getDashboardByRoute',
      getRoleByResourceId: 'projects/getRoleByResourceId'
    }),

    timezone() {
      return this.$route?.query?.timezone || 'utc'
    },
    time() {
      return getTimeRangeByRoute(this.$route)
    },
    timeRange() {
      const from = this.$route.query[qkFrom]
      const to = this.$route.query[qkTo]

      return [from, to]
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    isProjectOwner() {
      return this.project?.isOwner
    },
    // dashboard() {
    //   return this.getDashboardByRoute(this.$route)
    // },
    roleDashboard() {
      if (!this.dashboard) return {}

      return (
        this.getRoleByResourceId(this.project, this.dashboard.resourceId) || {}
      )
    },
    hasNoPanel() {
      return !this.panel.title
    },
    panelSrc() {
      const url = this.snapshotUrl

      if (!url) return

      return `${url}&theme=light`

      // // return `${url}?from=883612800000&to=1638403199000&orgId=1&panelId=2225&theme=light`
      // return `${url}?orgId=1&kiosk&fullscreen&theme=light`
    },
    showMenu() {
      if (this.isProjectOwner) return true

      const { delete: canDelete, update } = this.roleDashboard

      return canDelete || update
    }
  },

  watch: {
    panel: {
      handler(newVal, oldVal) {
        // panel有改變才重新撈取snapshotUrl
        if (this.hasNoPanel) return

        const newPanel = (newVal && JSON.stringify(newVal)) || ''
        const oldPanel = (oldVal && JSON.stringify(oldVal)) || ''

        if (newPanel === oldPanel) {
          return
        }

        this.fetchSnapshotUrl()
      },
      deep: true,
      immediate: true
    },
    useFeatureProperties: {
      handler(newVal, oldVal) {
        // panel有改變才重新撈取snapshotUrl
        if (this.hasNoPanel) return

        const newTeamplating = (newVal && JSON.stringify(newVal)) || ''
        const oldTeamplating = (oldVal && JSON.stringify(oldVal)) || ''

        if (newTeamplating === oldTeamplating) {
          return
        }

        this.fetchSnapshotUrl()
      },
      deep: true
    },
    timezone(newVal, oldVal) {
      if (newVal === oldVal) return

      this.fetchSnapshotUrl()
    },
    timeRange: {
      handler(newVal, oldVal) {
        if (newVal.every((t, iT) => t === oldVal[iT])) return

        this.fetchSnapshotUrl()
      },
      deep: true
    }
  },

  methods: {
    refresh() {
      this.fetchSnapshotUrl()
    },
    async fetchSnapshotUrl() {
      this.error = null

      if (this.hasNoPanel) return

      this.isLoading = true
      const { resourceId, ...dashboard } = this.dashboard
      const timezone = this.timezone
      const time = this.time
      this.snapshotUrl = await this.$store
        .dispatch('panels/fetchSnapshotUrl', {
          panel: this.panel,
          dashboard,
          time,
          dashboardOptions: {
            timezone
          },
          useFeatureProperties: this.useFeatureProperties
        })
        .then(snapshotUrl => {
          if (
            timezone !== this.timezone ||
            (time == null && this.time != null) ||
            (time && time.some((t, iT) => t !== this.time[iT]))
          ) {
            return
          }

          return snapshotUrl
        })
        .catch(error => {
          if (
            timezone !== this.timezone ||
            (time == null && this.time != null) ||
            (time && time.some((t, iT) => t !== this.time[iT]))
          ) {
            return
          }

          this.error = String(error)
        })
        .finally(() => {
          if (
            timezone !== this.timezone ||
            (time == null && this.time != null) ||
            (time && time.some((t, iT) => t !== this.time[iT]))
          ) {
            return
          }

          this.isLoading = false
        })
    },
    deletePanel() {
      if (this.hasNoPanel) return

      const confirm = window.confirm(this.$t('confirm_delete_item', {
        name: this.panel.title
      }))

      if (!confirm) return

      this.$emit('delete', this.panel)
    },
    editPanel() {
      this.$store
        .dispatch('panels/form/editPanel', {
          panel: this.panel
        })
        .then(() => {
          this.$router.push({
            name: 'PanelEditor',
            params: {
              ...this.$route.params,
              panelId: this.panel.id
            },
            query: this.$route.query
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-card {
  position: relative;

  .panel-menu-activator {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.v-list .v-list-item .panel-menu-list-item-icon {
  margin-right: 12px;
}
</style>
