<template>
  <v-menu
    v-model="menu"
    content-class="custom-time-range-menu-content"
    bottom
    left
    :nudge-bottom="48"
    :transition="false"
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :attrs="attrs"
        :on="on"
      />
    </template>

    <v-sheet outlined>
      <v-row justify="center">
        <v-col cols="auto">
          <DateTimePicker
            v-model="from"
            :title="$t('from')"
          />
        </v-col>
        <v-col cols="auto">
          <DateTimePicker
            v-model="to"
            :title="$t('to')"
            :min="from"
          />
        </v-col>
      </v-row>

      <v-row
        class="mb-0"
        justify="center"
      >
        <v-spacer />
        <v-spacer />
        <v-col cols="auto">
          <v-btn @click="close(), $emit('cancel')">
            {{
              $t('cancel')
            }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="apply"
          >
            {{ $t('apply') }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-spacer />
      </v-row>
    </v-sheet>
  </v-menu>
</template>

<script>
import moment from 'moment'
import DateTimePicker from './DateTimePicker'

moment.suppressDeprecationWarnings = true

const qkFrom = process.env.VUE_APP_QUERY_KEY_TIME_FROM
const qkTo = process.env.VUE_APP_QUERY_KEY_TIME_TO

const dateFormat = 'YYYY-MM-DD HH:mm'

const defaultTimeRange = () => [
  moment()
    .startOf('d')
    .format(dateFormat),
  moment()
    .endOf('d')
    .format(dateFormat)
]

export default {
  name: 'CustomTimeRange',

  components: {
    DateTimePicker
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    timeRange: defaultTimeRange()
  }),

  computed: {
    menu: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },

    from: {
      get() {
        return this.timeRange[0]
      },
      set(newVal) {
        this.timeRange.splice(0, 1, newVal)
      }
    },

    to: {
      get() {
        return this.timeRange[1]
      },
      set(newVal) {
        this.timeRange.splice(1, 1, newVal)
      }
    },

    routeTimeRange() {
      const { query = {} } = this?.$route || {}
      const { [qkFrom]: from, [qkTo]: to } = query

      const mRange = [moment(Number(from)), moment(Number(to))]

      if (mRange.some(t => !t.isValid())) {
        return null
      }

      return mRange.map(t => t.format(dateFormat))
    }
  },

  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.initTimeRange()
        }
      },
      immediate: true
    }
  },

  methods: {
    initTimeRange() {
      if (!this.routeTimeRange) {
        this.timeRange = defaultTimeRange()

        return
      }

      this.timeRange = this.routeTimeRange.slice()
    },

    apply() {
      const mRange = this.timeRange.map(t => moment(t))

      // format error
      if (mRange.some(t => !t.isValid())) {
        this.$store.dispatch('snackbar/showErrorMsg', {
          content: this.$t('valid.dateFormatError')
        })

        return
      }

      // range error
      if (mRange[1].isSameOrBefore(mRange[0])) {
        this.$store.dispatch('snackbar/showErrorMsg', {
          content: this.$t('valid.dateRangeError')
        })

        return
      }

      this.$emit(
        'submit',
        mRange.map(t => t._d)
      )

      this.close()
    },
    close() {
      this.menu = false
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-time-range-menu-content {
  width: 640px;
  max-width: unset;
}
</style>
