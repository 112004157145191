<template>
  <CustomTimeRange
    v-model="menu"
    @submit="onCustomSubmit"
    @cancel="onCustomCancel"
  >
    <template #activator>
      <v-select
        v-model="selected"
        v-bind="selectProps"
        class="pt-0 mt-0"
        eager
        hide-details="auto"
        :disabled="selected === 'custom'"
        :items="items"
        :value-comparator="selectedValueComparator"
        :menu-props="{
          offsetY: true,
          bottom: true
        }"
        style="max-width: 360px;"
        @change="onSelectChange"
      >
        <template #selection>
          <v-row
            no-gutters
            class="text-truncate"
            :class="{ 'white--text': selection !== 'custom' }"
            align="center"
          >
            {{ selection }}
          </v-row>
        </template>
      </v-select>
    </template>
  </CustomTimeRange>
</template>

<script>
import CustomTimeRange from './CustomTimeRange'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import i18n from '@/i18n'

const qkFrom = process.env.VUE_APP_QUERY_KEY_TIME_FROM
const qkTo = process.env.VUE_APP_QUERY_KEY_TIME_TO

const s = '#' // time range separator

// const defaultSelected = `now-14d${s}now`
const valueZoomToData = 'zoom2data'
const defaultSelected = valueZoomToData
const items = () => [
  {
    text: i18n.t('zoom_to_data'),
    value: valueZoomToData,
    disabled: false
  },
  {
    text: i18n.t('absolute_time_range'),
    value: 'custom',
    disabled: false
  },
  {
    text: 'Last 5 Minutes',
    value: `now-5m${s}now`,
    disabled: false
  },
  {
    text: 'Last 15 Minutes',
    value: `now-15m${s}now`,
    disabled: false
  },
  {
    text: 'Last 30 Minutes',
    value: `now-30m${s}now`,
    disabled: false
  },
  {
    text: 'Last 1 Hour',
    value: `now-1h${s}now`,
    disabled: false
  },
  {
    text: 'Last 3 Hours',
    value: `now-3h${s}now`,
    disabled: false
  },
  {
    text: 'Last 6 Hours',
    value: `now-6h${s}now`,
    disabled: false
  },
  {
    text: 'Last 12 Hours',
    value: `now-12h${s}now`,
    disabled: false
  },
  {
    text: 'Last 24 Hours',
    value: `now-24h${s}now`,
    disabled: false
  },
  {
    text: 'Last 2 Days',
    value: `now-2d${s}now`,
    disabled: false
  },
  {
    text: 'Last 7 Days',
    value: `now-7d${s}now`,
    disabled: false
  },
  {
    text: 'Last 14 Days',
    value: `now-14d${s}now`,
    disabled: false
  },
  {
    text: 'Last 1 Months',
    value: `now-1M${s}now`,
    disabled: false
  },
  {
    text: 'Last 3 Months',
    value: `now-3M${s}now`,
    disabled: false
  },
  {
    text: 'Last 6 Months',
    value: `now-6M${s}now`,
    disabled: false
  },
  {
    text: 'Last 1 Year',
    value: `now-1y${s}now`,
    disabled: false
  },
  {
    text: 'Yesterday',
    value: `now-1d${s}now-1d`,
    disabled: false
  },
  {
    text: 'Today',
    value: `now/d${s}now/d`,
    disabled: false
  },
  {
    text: 'Today so Far',
    value: `now/d${s}now`,
    disabled: false
  },
  {
    text: 'This Day Last Week',
    value: `now-7d/d${s}now-7d/d`,
    disabled: false
  },
  {
    text: 'Previous Week',
    value: `now-1w${s}now-1w`,
    disabled: false
  },
  {
    text: 'This Week',
    value: `now/w${s}now/w`,
    disabled: false
  },
  {
    text: 'This Week so Far',
    value: `now/w${s}now`,
    disabled: false
  },
  {
    text: 'Previous Mounth',
    value: `now-1M${s}now-1M`,
    disabled: false
  },
  {
    text: 'This Mounth',
    value: `now/M${s}now/M`,
    disabled: false
  },
  {
    text: 'This Mounth so Far',
    value: `now/M${s}now`,
    disabled: false
  },
  {
    text: 'Previous Year',
    value: `now-1y${s}now-1y`,
    disabled: false
  },
  {
    text: 'This Year',
    value: `now/y${s}now/y`,
    disabled: false
  },
  {
    text: 'This Year so Far',
    value: `now/y${s}now`,
    disabled: false
  }
]

export const getTimeRangeByRoute = (route = {}) => {
  const { [qkFrom]: from, [qkTo]: to } = route.query || {}

  if (!from || !to) {
    return // zoom to data case
  }

  return calTimeRange([from, to])
}

export const calTimeRange = routeTimes => {
  // routeTime = 'now' | 'now-1h' | 'now/d' | '1595404473816' | undefined

  const now = new Date()

  if (
    !Array.isArray(routeTimes) ||
    routeTimes.length !== 2 ||
    routeTimes.some(t => t === undefined || t === null)
  ) {
    // times = defaultSelected.split(s)
    return // zoom to data case
  }

  const times = routeTimes.slice()

  return times
    .map(t => t.replace('now', '')) // '/d' or '-1h' or 1596663718
    .map((t, iTime) => {
      // +moment() = moment().valueOf() = Unix Timestamp (milliseconds)
      if (t === '') return +moment(now)

      const count = parseInt(t)

      // timestamp case
      if (count > 1000) return Number(t)

      const unit = t.slice(-1)

      let mTime = moment(now)

      // This xx so Far
      if (isNaN(count)) {
        return !iTime ? +mTime.startOf(unit) : +mTime.endOf(unit)
      }

      mTime = mTime.add(count, unit)

      // last xx to now
      if (times[0] !== times[1]) {
        return +mTime
      }

      // previous xx
      return !iTime ? +mTime.startOf(unit) : +mTime.endOf(unit)
    })
}

export default {
  name: 'TimeRangeSelect',

  components: {
    CustomTimeRange
  },

  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    menu: false,
    selected: ''
  }),

  computed: {
    items,
    selectProps() {
      const props = { ...this.$props }
      delete props.value

      return props
    },
    routeSelected() {
      const { query = {} } = this?.$route || {}
      const { [qkFrom]: from, [qkTo]: to } = query

      return from && to ? `${from}${s}${to}` : this.selected
    },
    selection() {
      const item = this.items.find(item => item.value === this.selected)

      if (item) {
        return item?.text
      }

      if (!this.selected) {
        return ''
      }

      const customSelected = this.selected
        .split(s)
        .map(t => moment(Number(t)).format('YYYY-MM-DD HH:mm'))
        .join(` ${this.$t('to').toLowerCase()} `)

      return customSelected
    }
  },

  watch: {
    $route: {
      handler(newRoute) {
        this.initSelect(newRoute)
      },
      immediate: true
    }
  },

  methods: {
    initSelect(route) {
      const { query = {} } = route || {}
      const { [qkFrom]: from, [qkTo]: to } = query

      const selected = from && to ? `${from}${s}${to}` : ''

      // Give default selected
      if (!selected) {
        this.selected = defaultSelected

        this.onSelectChange(defaultSelected)

        return
      }

      // Only diff need to update route query value
      if (selected !== this.selected) {
        this.selected = selected

        this.onSelectChange(selected)
      }
    },

    onSelectChange(val) {
      if (val === 'custom') {
        this.showCustomPicker()

        return
      }

      const times = val.split(s)

      this.updateRouteTimeRange(times)
    },

    updateRouteTimeRange(times) {
      const { name = '', params = {}, query = {} } = this.$route || {}

      const isSameTimeRange = q => {
        const qKeys = Object.keys(q)

        if (
          !qKeys.length && (query[qkFrom] || query[qkFrom])) {
          return false
        }

        return qKeys.every(
          key => q[key] === query[key]
        )
      }

      if (!Array.isArray(times) || times.length !== 2) {
        const newQuery = cloneDeep(query)
        delete newQuery[qkFrom]
        delete newQuery[qkTo]

        if (!isSameTimeRange(newQuery)) {
          this.$router.replace({
            name,
            params,
            query: newQuery
          })
        }

        return
      }

      const newQuery = {}
      newQuery[qkFrom] = `${times[0]}`
      newQuery[qkTo] = `${times[1]}`

      if (!isSameTimeRange(newQuery)) {
        this.$router.replace({
          name,
          params,
          query: {
            ...query,
            ...newQuery
          }
        })
      }
    },

    showCustomPicker() {
      this.menu = true
    },

    onCustomSubmit(range) {
      if (!Array.isArray(range) || range.length !== 2) return

      const mTimes = range.map(t => moment(t))

      const isValid = mTimes.map(t => t.isValid()).every(Boolean)

      if (!isValid) return

      const customSelected = mTimes.map(t => `${+t}`).join(s)

      // +moment() = moment().valueOf() = Unix Timestamp (milliseconds)
      this.onSelectChange(customSelected)

      // Update selection from 'custom' to customSelected
      if (customSelected === this.routeSelected) {
        this.selected = customSelected
      }
    },

    onCustomCancel() {
      this.selected = this.routeSelected
    },

    selectedValueComparator(itemValue, selected) {
      // If selected no match any items, show custom selected and selection text

      const target =
        this?.items?.find(item => item.value === selected)?.value || 'custom'

      return itemValue === target
    }
  }
}
</script>

<style lang="scss" scoped></style>
